import { IAudienceModalFilter } from '../types/CommonTypes';

export const AudienceFilterLabels: { [key: string]: string } = {
	includeFilters: 'Include Filters',
	excludeFilters: 'Exclude Filters',
	includedFilters: 'Included Filters',
	excludedFilters: 'Excluded Filters',
	selectedFilters: 'Selected Filters',
	noFiltersSelected: 'No Filters Selected',
	results: 'Results',
	noResults: 'No Results',
	manageFilters: 'Manage Filters',
	fallbackText: 'Select Filters to View Results',
	totalAudience: 'Total Audience',
	apply: 'Apply',
	selectFilters: 'Select Filters',
	resetAll: 'Reset All',
	applyButtonTooltip: 'Select some filter to continue',
	selectSubgroupMsg: 'Please select some sub-group',
	reset: 'Reset',
};

export const initialFilterValue: IAudienceModalFilter = {
	people_leader: [],
	worker_type: [],
	work_region: [],
	work_country: [],
	sector: [],
	mrc_code: [],
	legal_entity: [],
	job_family: [],
	job_family_group: [],
	job_group: [],
	employee_function: [],
	manager_wwid: [],
	wwid: [],
};

export const initialFilterLabelJson: string = JSON.stringify({
	includeFilters: initialFilterValue,
	excludeFilters: initialFilterValue,
});

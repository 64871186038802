import {
	AnyAction,
	createAsyncThunk,
	createSlice,
	PayloadAction,
	ThunkDispatch,
} from '@reduxjs/toolkit';
import { RootState } from '../app/Store';
import {
	FetchSurveyList,
	FetchAudienceFilterList,
	FetchConfigData,
	PostSelectedAudienceFilteredData,
	PostFetchAudienceData,
	BatchReportRequest,
	ExportUsersAsList,
	PostQuestionnaireData,
	GetQuestionnaireByID,
	PostSelectedAudienceCollectionFilteredData,
	GetQuestionnaireDeactiveResponse,
	PutQuestionnaireDetails,
	DeleteDraftQuestionnaire,
	FetchUserWWID,
} from '../middleware/SurveyService';
import {
	ISurveyListingRequest,
	ISurveyState,
	IDateFilter,
	ISurveyListingDataItem,
	ISurveyConfigurationData,
	IRemoveSelectedFilters,
	IAppliedFilterArray,
	IFetchAudiencePayload,
	IExportAudiencePayload,
	IAudienceListingRequest,
	IType,
	IReportResponsePayloadData,
	IReportRequestPayload,
	IUsersDataItems,
} from '../types/CommonTypes';
import {
	IPostSelectedAudienceFilteredData,
	IQuestionnaireDataRequest,
	ISurveyFormsQuestionnaire,
} from '../types/SurveyProps';
import { API_STATUS_CONSTANT, RESPONSE_CODE } from '../constants/StepConstants';
import { getKey, getQuestionnaireByIdResponse } from '../utils/Helpers';
import { Numbers, QUE_JOURNEY_STEPS } from '../utils/Enum';
import {
	initialFilterValue,
	initialFilterLabelJson,
} from '../utils/SurveyAudience';

export const initialState: ISurveyState = {
	isLastScreenEndUser: false,
	homeScreenActivatedTab: Numbers.zero,
	questionnaireDataStatus: 'idle',
	userWWIDStatus: 'idle',
	questionnaireStatus: 'idle',
	status: 'idle',
	errMsg: { code: '', errorMessage: '' },
	questionnaireDataByIdStatus: 'idle',
	questionnaireDeactivatedStatus: 'idle',
	draftQuestionnaireDeletedStatus: 'idle',
	audienceFilterResponse: 'idle',
	exportUsersListResponse: 'idle',
	batchReportRequestResponse: 'idle',
	surveyListingRequest: {} as ISurveyListingRequest,
	userWWIDListData: [],
	surveyAllListingData: [],
	surveyModifiedListingData: [],
	pageIndex: Numbers.zero,
	pageSize: Numbers.ten,
	surveyFilterData: [],
	dateFilter: {
		startDate: '',
		endDate: '',
		createdAtStartDate: '',
		createdAtEndDate: '',
	},
	questionnaireCopyDataStatus: 'idle',
	surveyAudienceIncludedFilterData: {},
	surveyAudienceExcludedFilterData: {},
	appliedFilterData: [] as IUsersDataItems[],
	verifiedAudienceWWIDs: [],
	invalidAudienceWWIDs: [],
	surveyQuestionnaireData: {
		surveyFormsDetails: {
			surveyName: '',
			surveyType: '',
			questionnaireFor: '',
			surveyOwningLocation: '',
			surveyStartDate: null,
			surveyDueDate: null,
			surveyDuration: Numbers.zero,
			surveyDescription: '',
			status: 'In Draft',
			isFromNotification: false,
		},
		surveyFormsAudience: '',
		surveyIncludedAudienceFilter: JSON.stringify(initialFilterValue),
		surveyExcludedAudienceFilter: JSON.stringify(initialFilterValue),
		surveyFormsQuestion: [],
		notificationConfig: {
			initialEmailSubject: '',
			initialEmailBody: '',
			reminderEmailSubject: '',
			reminderEmailBody: '',
			reminderEmailFrequency: [],
			overdueEmailSubject: '',
			overdueEmailFrequency: [],
			overdueEmailBody: '',
		},
		currentStep: QUE_JOURNEY_STEPS.STEP_BASIC_DETAILS,
		description: '',
		instruction: '',
		audienceCount: Numbers.zero,
		includeClause: '',
		excludeClause: '',
		filterLabelJson: initialFilterLabelJson,
		shouldTriggerAPI: false,
		userEmailId: '',
	} as ISurveyFormsQuestionnaire,
	surveyConfigurationData: {} as ISurveyConfigurationData,
	removeSelectedFilters: {} as IRemoveSelectedFilters,
	activateApplyFilter: false,
	selectedFilterRequestPayload: {
		includeFilters: JSON.stringify(
			initialFilterValue
		) as IAudienceListingRequest,
		excludeFilters: JSON.stringify(
			initialFilterValue
		) as IAudienceListingRequest,
		includeClause: '',
		excludeClause: '',
		filterLabelJson: initialFilterLabelJson,
		pageSize: Numbers.ten,
		pageNumber: Numbers.one,
	} as IAppliedFilterArray,
	dummySelectedFilterRequestPayload: {
		includeFilters: JSON.stringify(
			initialFilterValue
		) as IAudienceListingRequest,
		excludeFilters: JSON.stringify(
			initialFilterValue
		) as IAudienceListingRequest,
		includeClause: '',
		excludeClause: '',
		filterLabelJson: initialFilterLabelJson,
		pageSize: Numbers.ten,
		pageNumber: Numbers.one,
	} as IAppliedFilterArray,
	filterRegion: [],
	filterRegionCountryData: {},
	filterJobFunction: [],
	filterAllJobFunctions: {},
	exportUsersListExcelData: null,
	resetSelectedAudienceFilter: false,
	deactivatedResponse: [],
	deletedDraftResponse: '',
	questionnaireId: '',
};

export const fetchConfigData = createAsyncThunk(
	'Survey/FetchConfigData',
	async (_, { dispatch, getState }) => {
		FetchConfigData()
			.then((response) => {
				if (response?.data?.data) {
					const state = getState() as RootState; //NOSONAR
					const configData = response?.data?.data;
					dispatch(
						setConfigurationData({
							...state?.survey?.surveyConfigurationData,
							...configData,
						})
					);
				}
			})
			.catch(() => {});
	}
);

export const getQuestionnaireByIdData = createAsyncThunk(
	'Survey/FetchServeyById',
	async (req: { id: string }, { dispatch }) => {
		GetQuestionnaireByID(req)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data?.data
				) {
					dispatch(setQuestionnaireByIdResponse(response?.data?.data));
					dispatch(setQuestionnaireDataByIdStatus(API_STATUS_CONSTANT.SUCCESS));
				} else {
					dispatch(setQuestionnaireDataByIdStatus(API_STATUS_CONSTANT.FAILED));
				}
			})
			.catch(() => {
				dispatch(setQuestionnaireDataByIdStatus(API_STATUS_CONSTANT.FAILED));
			});
	}
);

export const getUserWWID = createAsyncThunk(
	'Survey/FetchUserWWID',
	async (userWWIDReq: string, { dispatch }) => {
		FetchUserWWID(userWWIDReq)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data?.data
				) {
					dispatch(setUserWWID(response?.data?.data));
					dispatch(setUserWWIDStatus('success'));
				} else {
					dispatch(setUserWWID([]));
					dispatch(setUserWWIDStatus('failed'));
				}
			})
			.catch(() => {
				dispatch(setUserWWID([]));
				dispatch(setUserWWIDStatus('failed'));
			});
	}
);

export const getSurveyData = createAsyncThunk(
	'Survey/FetchSurveyList',
	async (surveyListingReq: ISurveyListingRequest, { dispatch }) => {
		FetchSurveyList(surveyListingReq)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data?.data
				) {
					const questionnaireList =
						response?.data?.data?.questionnaireList?.map(
							(item: {
								createdAt: string;
								startDate: string;
								dueDate: string;
							}) => {
								item.createdAt = item.createdAt.split('T')[0];
								item.startDate = item.startDate.split('T')[0];
								item.dueDate = item.dueDate.split('T')[0];
								return item;
							}
						);
					dispatch(setAllSurveyResponse(questionnaireList));
					dispatch(setModifiedSurveyResponse(questionnaireList));
					dispatch(setQuestionnaireDataStatus('success'));
				} else {
					dispatch(setAllSurveyResponse([]));
					dispatch(setModifiedSurveyResponse([]));
					dispatch(setQuestionnaireDataStatus('failed'));
				}
			})
			.catch(() => {
				dispatch(setAllSurveyResponse([]));
				dispatch(setModifiedSurveyResponse([]));
				dispatch(setQuestionnaireDataStatus('failed'));
			});
	}
);

export const getAudienceFilterData = createAsyncThunk(
	'Survey/FetchAudienceFilterList',
	async (_, { dispatch }) => {
		FetchAudienceFilterList()
			.then((response) => {
				const surveyAudienceFilters = response?.data?.data;
				if (surveyAudienceFilters) {
					dispatch(
						setSurveyAudienceExcludedFilterResponse(surveyAudienceFilters)
					);
				}
				if (surveyAudienceFilters) {
					dispatch(
						setSurveyAudienceIncludedFilterResponse(surveyAudienceFilters)
					);
					if (
						Object.prototype.hasOwnProperty.call(
							surveyAudienceFilters,
							'work_region-work_country'
						)
					) {
						dispatch(
							setFilterRegion(
								getKey(surveyAudienceFilters['work_region-work_country'])
							)
						);
						dispatch(
							setFilterRegionCountryData(
								surveyAudienceFilters['work_region-work_country']
							)
						);
					}
					if (
						Object.prototype.hasOwnProperty.call(
							surveyAudienceFilters,
							'job_family_group-job_family'
						)
					) {
						dispatch(
							setFilterJobFunction(
								getKey(surveyAudienceFilters['job_family_group-job_family'])
							)
						);
						dispatch(
							setFilterAllJobFunctions(
								surveyAudienceFilters['job_family_group-job_family']
							)
						);
					}
					dispatch(setAudienceFilterResponse('success'));
				}
			})
			.catch(() => {
				dispatch(setAudienceFilterResponse('failed'));
			});
	}
);

export const updateQuestionnaireData = createAsyncThunk(
	'Survey/PostQuestionnaireData',
	async (
		questionnaireReq: IQuestionnaireDataRequest,
		{ getState, dispatch }
	) => {
		// if duration is there in request, due date is always set to null from UI- As aligned with API team
		if (questionnaireReq.basicDetails['duration'] > 0) {
			questionnaireReq.basicDetails['dueDate'] = null;
		}
		PostQuestionnaireData(questionnaireReq, { skipGlobalErrorHandling: true })
			.then((response) => {
				const state = getState() as RootState; //NOSONAR
				if (response?.status === RESPONSE_CODE.HTTP_STATUS_OK) {
					dispatch(resetAPIFlag('success'));
					dispatch(
						updateSurveyQuestionnaire({
							...state?.survey.surveyQuestionnaireData,
							surveyFormsDetails: {
								...state?.survey.surveyQuestionnaireData.surveyFormsDetails,
								id: response.data.data.id,
								questionnaireCode: response.data.data.questionnaireCode,
								surveyDueDate: response?.data?.data?.dueDate
									? new Date(response?.data?.data?.dueDate)
									: null,
							},
							shouldTriggerAPI: false,
						})
					);
				} else if (response?.status === RESPONSE_CODE.HTTP_STATUS_NO_CONTENT) {
					dispatch(resetAPIFlag('success'));
				} else {
					dispatch(resetAPIFlag('failed'));
				}
			})
			.catch((e) => {
				if (e?.response?.status !== RESPONSE_CODE.HTTP_STATUS_OK) {
					dispatch(
						SetErrorMessage({
							code: e.code || '',
							errorMessage: e.response?.data?.message || '',
						})
					);
					dispatch(resetAPIFlag('failed'));
				} else {
					dispatch(resetAPIFlag('idle'));
				}
				return null;
			});
	}
);

export const updateAudienceFilterListingData = createAsyncThunk(
	'Survey/PostAudienceFilterApplyedData',
	async (audienceFilterReq: IAppliedFilterArray, { dispatch }) => {
		PostSelectedAudienceFilteredData(audienceFilterReq)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data?.data
				) {
					dispatch(setSelectedFilterResponse(response.data.data.users));
					dispatch(setSurveyAudienceCount(response.data.data.recordCount));
					dispatch(
						setSurveyAudienceIncludeClause(response.data.data.includeClause)
					);
					dispatch(
						setSurveyAudienceExcludeClause(response.data.data.excludeClause)
					);
					dispatch(
						setSurveyAudienceFilterLabelJson(response.data.data.filterLabelJson)
					);
				} else {
					resetAudienceStates(dispatch);
				}
			})
			.catch(() => {
				resetAudienceStates(dispatch);
			});
	}
);

export const resetAudienceStates = (
	dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
	dispatch(setSelectedFilterResponse([]));
	dispatch(setSurveyAudienceCount(0));
	dispatch(setSurveyAudienceIncludeClause(''));
	dispatch(setSurveyAudienceExcludeClause(''));
	dispatch(setSurveyAudienceFilterLabelJson(initialFilterLabelJson));
};
export const CopyQuestionnaireDetail = createAsyncThunk(
	'Survey/PutQuestionnaireDetails',
	async (req: IType, { dispatch }) => {
		PutQuestionnaireDetails(req)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data?.data
				) {
					dispatch(setQuestionnaireByIdResponse(response?.data?.data));
					dispatch(setQuestionnaireCopyDataStatus(API_STATUS_CONSTANT.SUCCESS));
				} else {
					dispatch(setQuestionnaireCopyDataStatus(API_STATUS_CONSTANT.FAILED));
				}
			})
			.catch(() => {
				dispatch(setQuestionnaireCopyDataStatus(API_STATUS_CONSTANT.FAILED));
			});
	}
);
export const updateUploadedAudienceListingData = createAsyncThunk(
	'Survey/PostFetchAudienceData',
	async (audienceFilterReq: IFetchAudiencePayload, { dispatch }) => {
		PostFetchAudienceData(audienceFilterReq)
			.then((response) => {
				dispatch(setSurveyAudienceCount(response.data.data.recordCount));
				dispatch(setValidWWIDsResponse(response.data.data.recepients));
			})
			.catch(() => {});
	}
);

export const updateAudienceCollectionFilterListingData = createAsyncThunk(
	'Survey/PostAudienceCollectionFilterApplyedData',
	async (
		audienceFilterReq: IPostSelectedAudienceFilteredData,
		{ dispatch }
	) => {
		PostSelectedAudienceCollectionFilteredData(audienceFilterReq)
			.then((response) => {
				const blobName = JSON.parse(JSON.stringify(response.data)).data
					?.blobName;
				const data = JSON.parse(JSON.stringify(response.data)).data
					.verifiedWWIDs;
				const invalidWWIDs = JSON.parse(JSON.stringify(response.data)).data
					.invalidWWIDs;
				dispatch(setInvalidWWIDsResponse(invalidWWIDs));
				dispatch(setSurveyAudienceCount(data.length));
				dispatch(setValidWWIDsResponse(data));
				dispatch(setSurveyAudienceIncludeClause(blobName));
			})
			.catch(() => null);
	}
);

export const batchReportRequest = createAsyncThunk(
	'Survey/BatchReportRequest',
	async (audienceFilterReq: IReportRequestPayload, { dispatch }) => {
		BatchReportRequest(audienceFilterReq)
			.then((response: IReportResponsePayloadData) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data
				) {
					dispatch(setBatchReportRequestResponse('success'));
				} else {
					dispatch(setBatchReportRequestResponse('failed'));
				}
			})
			.catch(() => {
				dispatch(setBatchReportRequestResponse('failed'));
			});
	}
);

export const exportUsersAsList = createAsyncThunk(
	'Survey/ExportUsersAsList',
	async (audienceFilterReq: IExportAudiencePayload, { dispatch }) => {
		ExportUsersAsList(audienceFilterReq)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data
				) {
					dispatch(setExportUsersListExcelData(response?.data));
					dispatch(setExportUsersListResponse('success'));
				} else {
					dispatch(setExportUsersListResponse('failed'));
				}
			})
			.catch(() => {
				dispatch(setExportUsersListResponse('failed'));
			});
	}
);

export const getQuestionnaireDeactivatedData = createAsyncThunk(
	'Survey/FetchServeyOfDeactivatedData',
	async (req: { id: string }, { dispatch }) => {
		GetQuestionnaireDeactiveResponse(req)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data?.data
				) {
					dispatch(setQuestionnaireDeactiveateResponse(response?.data?.data));
					dispatch(
						setQuestionnaireDeactivatedStatus(API_STATUS_CONSTANT.SUCCESS)
					);
				} else {
					dispatch(
						setQuestionnaireDeactivatedStatus(API_STATUS_CONSTANT.FAILED)
					);
				}
			})
			.catch(() => {
				dispatch(setQuestionnaireDeactivatedStatus(API_STATUS_CONSTANT.FAILED));
			});
	}
);

export const getDeleteDraftQuestionnaireData = createAsyncThunk(
	'Survey/FetchDeleteDraftQuestionnaireData',
	async (req: { id: string }, { dispatch }) => {
		DeleteDraftQuestionnaire(req)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data?.message
				) {
					dispatch(
						setDeleteDraftQuestionnaireResponse(response?.data?.message)
					);
					dispatch(
						setDeleteDraftQuestionnaireStatus(API_STATUS_CONSTANT.SUCCESS)
					);
				} else {
					dispatch(
						setDeleteDraftQuestionnaireStatus(API_STATUS_CONSTANT.FAILED)
					);
				}
			})
			.catch(() => {
				dispatch(setDeleteDraftQuestionnaireStatus(API_STATUS_CONSTANT.FAILED));
			});
	}
);

export const surveySlice = createSlice({
	name: 'survey',
	initialState,
	reducers: {
		setFilterRegion: (state, action: PayloadAction<string[]>) => {
			state.filterRegion = action.payload;
		},
		setFilterRegionCountryData: (state, action: PayloadAction<object>) => {
			state.filterRegionCountryData = action.payload;
		},
		setFilterJobFunction: (state, action: PayloadAction<string[]>) => {
			state.filterJobFunction = action.payload;
		},
		setFilterAllJobFunctions: (state, action: PayloadAction<object>) => {
			state.filterAllJobFunctions = action.payload;
		},
		setQuestionnaireByIdResponse: (
			state,
			action: PayloadAction<IQuestionnaireDataRequest>
		) => {
			state.surveyQuestionnaireData = {
				...state.surveyQuestionnaireData,
				...getQuestionnaireByIdResponse(action.payload),
			};
		},
		setFilterDate(state, action: PayloadAction<IDateFilter>) {
			state.dateFilter = action.payload;
		},
		setSurveyPayload: (
			state,
			action: PayloadAction<{ item: string; val: string | null }>
		) => {
			const { item, val } = action.payload;
			if (item === 'location') {
				state.surveyListingRequest.location = val;
			} else if (item === 'status') {
				state.surveyListingRequest.status = val;
			} else if (item === 'questionnaireName') {
				state.surveyListingRequest.questionnaireName = val;
			} else {
				state.surveyListingRequest.questionnaireAuthor = val;
			}
		},
		resetSurveyPayload: (state, action: PayloadAction<object>) => {
			state.surveyListingRequest = action.payload;
		},
		setAllSurveyResponse: (
			state,
			action: PayloadAction<ISurveyListingDataItem[]>
		) => {
			state.surveyAllListingData = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setUserWWID: (state, action: PayloadAction<[]>) => {
			state.userWWIDListData = action.payload;
		},
		setModifiedSurveyResponse: (
			state,
			action: PayloadAction<ISurveyListingDataItem[]>
		) => {
			state.surveyModifiedListingData = action.payload;
		},
		setSurveyAudienceIncludedFilterResponse: (
			state,
			action: PayloadAction<object>
		) => {
			state.surveyAudienceIncludedFilterData = action.payload;
		},
		setSurveyAudienceExcludedFilterResponse: (
			state,
			action: PayloadAction<object>
		) => {
			state.surveyAudienceExcludedFilterData = action.payload;
		},
		updateSurveyQuestionnaire: (
			state,
			action: PayloadAction<ISurveyFormsQuestionnaire>
		) => {
			state.surveyQuestionnaireData = action.payload;
		},
		setPageIndex: (state, action: PayloadAction<number>) => {
			state.pageIndex = action.payload;
		},
		setRowsPerPageValue: (state, action: PayloadAction<number>) => {
			state.pageSize = action.payload;
		},
		setConfigurationData: (
			state,
			action: PayloadAction<ISurveyConfigurationData>
		) => {
			state.surveyConfigurationData = action.payload;
		},
		setSelectedFilterResponse: (state, action) => {
			state.appliedFilterData = action.payload;
		},
		setValidWWIDsResponse: (state, action) => {
			state.verifiedAudienceWWIDs = action.payload;
		},
		setInvalidWWIDsResponse: (state, action) => {
			state.invalidAudienceWWIDs = action.payload;
		},
		removeSelectedFilters: (
			state,
			action: PayloadAction<{ key: string; val: string[] }>
		) => {
			const { key, val } = action.payload;
			if (key === 'haveReports') {
				state.removeSelectedFilters.haveReports = val;
			} else if (key === 'region') {
				state.removeSelectedFilters.region = val;
			} else if (key === 'goc') {
				state.removeSelectedFilters.goc = val;
			} else if (key === 'reportingManager') {
				state.removeSelectedFilters.reportingManager = val;
			} else {
				state.removeSelectedFilters.reportingManager = val;
			}
		},
		setSelectedFilterRequestPayload: (state, action) => {
			state.selectedFilterRequestPayload = action.payload;
		},
		setDummySelectedFilterRequestPayload: (state, action) => {
			state.dummySelectedFilterRequestPayload = action.payload;
		},
		resetAppliedFilterData: (state, action) => {
			state.appliedFilterData = action.payload;
		},
		triggerResetSelectedAudienceFilter: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.resetSelectedAudienceFilter = action.payload;
		},
		setHomeScreenActivatedTab: (state, action: PayloadAction<number>) => {
			state.homeScreenActivatedTab = action.payload;
		},
		setSurveyAudienceCount: (state, action) => {
			state.surveyQuestionnaireData.audienceCount = action.payload;
		},
		setSurveyAudienceBlobName: (state, action) => {
			state.surveyQuestionnaireData.surveyIncludedAudienceFilter =
				action.payload;
		},
		setSurveyExAudienceBlobName: (state, action) => {
			state.surveyQuestionnaireData.surveyExcludedAudienceFilter =
				action.payload;
		},
		setQuestionnaireDataStatus: (
			state,
			action: PayloadAction<ISurveyState['questionnaireDataStatus']>
		) => {
			state.questionnaireDataStatus = action.payload;
		},
		setUserWWIDStatus: (
			state,
			action: PayloadAction<ISurveyState['userWWIDStatus']>
		) => {
			state.userWWIDStatus = action.payload;
		},
		setQuestionnaireStatus: (
			state,
			action: PayloadAction<ISurveyState['questionnaireStatus']>
		) => {
			state.questionnaireStatus = action.payload;
		},
		setQuestionnaireDataByIdStatus: (
			state,
			action: PayloadAction<ISurveyState['questionnaireDataByIdStatus']>
		) => {
			state.questionnaireDataByIdStatus = action.payload;
		},
		setQuestionnaireCopyDataStatus: (
			state,
			action: PayloadAction<ISurveyState['questionnaireCopyDataStatus']>
		) => {
			state.questionnaireCopyDataStatus = action.payload;
		},
		setQuestionnaireDeactivatedStatus: (
			state,
			action: PayloadAction<ISurveyState['questionnaireDeactivatedStatus']>
		) => {
			state.questionnaireDeactivatedStatus = action.payload;
		},
		setDeleteDraftQuestionnaireStatus: (
			state,
			action: PayloadAction<ISurveyState['draftQuestionnaireDeletedStatus']>
		) => {
			state.draftQuestionnaireDeletedStatus = action.payload;
		},
		SetErrorMessage: (state, action: PayloadAction<ISurveyState['errMsg']>) => {
			state.errMsg = action.payload;
		},
		resetAPIFlag: (state, action: PayloadAction<ISurveyState['status']>) => {
			state.status = action.payload;
		},
		setAudienceFilterResponse: (
			state,
			action: PayloadAction<ISurveyState['audienceFilterResponse']>
		) => {
			state.audienceFilterResponse = action.payload;
		},
		setExportUsersListResponse: (
			state,
			action: PayloadAction<ISurveyState['exportUsersListResponse']>
		) => {
			state.exportUsersListResponse = action.payload;
		},
		setBatchReportRequestResponse: (
			state,
			action: PayloadAction<ISurveyState['batchReportRequestResponse']>
		) => {
			state.batchReportRequestResponse = action.payload;
		},
		setExportUsersListExcelData: (state, action: PayloadAction<unknown>) => {
			state.exportUsersListExcelData = action.payload;
		},
		setSurveyAudienceIncludeClause: (state, action) => {
			state.surveyQuestionnaireData.includeClause = action.payload;
		},
		setSurveyAudienceExcludeClause: (state, action) => {
			state.surveyQuestionnaireData.excludeClause = action.payload;
		},
		setSurveyAudienceFilterLabelJson: (state, action) => {
			state.surveyQuestionnaireData.filterLabelJson = action.payload;
		},
		setActivateApplyFilter: (state, action) => {
			state.activateApplyFilter = action.payload;
		},
		setQuestionnaireDeactiveateResponse: (state, action) => {
			state.deactivatedResponse = action.payload;
		},
		setDeleteDraftQuestionnaireResponse: (state, action) => {
			state.deletedDraftResponse = action.payload;
		},
		setIsLastScreenEndUser: (state, action: PayloadAction<boolean>) => {
			state.isLastScreenEndUser = action.payload;
		},
		clearDropDownOptions: (state, action: PayloadAction<string>) => {
			const questionId = action.payload;
			const question = state.surveyQuestionnaireData.surveyFormsQuestion.find(
				(q) => q.questionId === questionId
			);
			if (question) {
				question.options = [];
			}
		},
		setQuestionnaireId: (state, action: PayloadAction<string>) => {
			state.questionnaireId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getQuestionnaireByIdData.pending, (state) => {
				state.questionnaireDataByIdStatus = 'loading';
			})
			.addCase(getQuestionnaireByIdData.rejected, (state) => {
				state.questionnaireDataByIdStatus = 'failed';
			})
			.addCase(CopyQuestionnaireDetail.pending, (state) => {
				state.questionnaireCopyDataStatus = 'loading';
			})
			.addCase(CopyQuestionnaireDetail.rejected, (state) => {
				state.questionnaireCopyDataStatus = 'failed';
			})
			.addCase(getQuestionnaireDeactivatedData.pending, (state) => {
				state.questionnaireDeactivatedStatus = 'loading';
			})
			.addCase(getDeleteDraftQuestionnaireData.pending, (state) => {
				state.draftQuestionnaireDeletedStatus = 'loading';
			})
			.addCase(getQuestionnaireDeactivatedData.rejected, (state) => {
				state.questionnaireDeactivatedStatus = 'failed';
			})
			.addCase(getDeleteDraftQuestionnaireData.rejected, (state) => {
				state.draftQuestionnaireDeletedStatus = 'failed';
			})
			.addCase(getSurveyData.pending, (state) => {
				state.questionnaireDataStatus = 'loading';
			})
			.addCase(getSurveyData.rejected, (state) => {
				state.questionnaireDataStatus = 'failed';
			})
			.addCase(getUserWWID.pending, (state) => {
				state.userWWIDStatus = 'loading';
			})
			.addCase(getUserWWID.rejected, (state) => {
				state.userWWIDStatus = 'failed';
			})
			.addCase(getAudienceFilterData.pending, (state) => {
				state.audienceFilterResponse = 'loading';
			})
			.addCase(getAudienceFilterData.rejected, (state) => {
				state.audienceFilterResponse = 'failed';
			});
	},
});
export const surveyListingRequest = (state: RootState) =>
	state.survey.surveyListingRequest;
export const pageIndex = (state: RootState) => state.survey.pageIndex;
export const pageSize = (state: RootState) => state.survey.pageSize;
export const surveyAllListingData = (state: RootState) =>
	state.survey.surveyAllListingData;
export const surveyModifiedListingData = (state: RootState) =>
	state.survey.surveyModifiedListingData;
export const surveyFilterData = (state: RootState) =>
	state.survey.surveyFilterData;
export const surveyQuestionnaireData = (state: RootState) =>
	state.survey.surveyQuestionnaireData;

export const configurationData = (state: RootState) =>
	state.survey.surveyConfigurationData;
export const getSurveyAudienceIncludeFilterData = (state: RootState) =>
	state.survey.surveyAudienceIncludedFilterData;
export const getSurveyAudienceExcludedFilterData = (state: RootState) =>
	state.survey.surveyAudienceExcludedFilterData;
export const selectSurveyFormsQuestion = (state: RootState) =>
	state.survey.surveyQuestionnaireData.surveyFormsQuestion;
export const appliedFilterData = (state: RootState) =>
	state.survey.appliedFilterData;
export const verifiedAudienceWWIDs = (state: RootState) =>
	state.survey.verifiedAudienceWWIDs;
export const invalidAudienceWWIDs = (state: RootState) =>
	state.survey.invalidAudienceWWIDs;
export const selectedFilterRequestPayloadData = (state: RootState) =>
	state.survey.selectedFilterRequestPayload;
export const dummySelectedFilterRequestPayloadData = (state: RootState) =>
	state.survey.dummySelectedFilterRequestPayload;
export const selectedAudienceCount = (state: RootState) =>
	state.survey.surveyQuestionnaireData.audienceCount;
export const APILoadingStatus = (state: RootState) => state.survey.status;
export const userWWIDSearchedData = (state: RootState) =>
	state.survey.userWWIDListData;
export const audienceFilterAPILoadingStatus = (state: RootState) =>
	state.survey.audienceFilterResponse;
export const getFilterRegion = (state: RootState) => state.survey.filterRegion;
export const getFilterRegionCountry = (state: RootState) =>
	state.survey.filterRegionCountryData;
export const getFilterJobFunction = (state: RootState) =>
	state.survey.filterJobFunction;
export const getFilterAllJobFunctions = (state: RootState) =>
	state.survey.filterAllJobFunctions;
export const exportUsersListResponse = (state: RootState) =>
	state.survey.exportUsersListResponse;
export const batchReportRequestResponse = (state: RootState) =>
	state.survey.batchReportRequestResponse;
export const exportUsersListExcelData = (state: RootState) =>
	state.survey.exportUsersListExcelData;
export const getResetSelectedAudienceFilter = (state: RootState) =>
	state.survey.resetSelectedAudienceFilter;
export const getSurveyStartDate = (state: RootState) =>
	state.survey.surveyQuestionnaireData.surveyFormsDetails.surveyStartDate;
export const getSurveyDueDate = (state: RootState) =>
	state.survey.surveyQuestionnaireData.surveyFormsDetails.surveyDueDate;
export const initialEmailBody = (state: RootState) =>
	state.survey.surveyQuestionnaireData.notificationConfig.initialEmailBody;
export const initialEmailSubject = (state: RootState) =>
	state.survey.surveyQuestionnaireData.notificationConfig.initialEmailSubject;
export const reminderEmailBody = (state: RootState) =>
	state.survey.surveyQuestionnaireData.notificationConfig.reminderEmailBody;
export const reminderEmailSubject = (state: RootState) =>
	state.survey.surveyQuestionnaireData.notificationConfig.reminderEmailSubject;
export const overdueEmailBody = (state: RootState) =>
	state.survey.surveyQuestionnaireData.notificationConfig.overdueEmailBody;
export const overdueEmailSubject = (state: RootState) =>
	state.survey.surveyQuestionnaireData.notificationConfig.overdueEmailSubject;
export const questionnaireDataByIdAPILoadingStatus = (state: RootState) =>
	state.survey.questionnaireDataByIdStatus;
export const questionnaireCopyDataAPILoadingStatus = (state: RootState) =>
	state.survey.questionnaireCopyDataStatus;
export const questionnaireDataAPILoadingStatus = (state: RootState) =>
	state.survey.questionnaireDataStatus;
export const userWWIDAPILoadingStatus = (state: RootState) =>
	state.survey.userWWIDStatus;
export const errorMessage = (state: RootState) => state.survey.errMsg;
export const questionnaireStatus = (state: RootState) =>
	state.survey.questionnaireStatus;
export const questionnaireInstructionsDetails = (state: RootState) =>
	state.survey.surveyQuestionnaireData.instruction;
export const getHomeScreenActivatedTab = (state: RootState) =>
	state.survey.homeScreenActivatedTab;
export const getActivateApplyFilter = (state: RootState) =>
	state.survey.activateApplyFilter;
export const questionnaireDeactivatedStatus = (state: RootState) =>
	state.survey.questionnaireDeactivatedStatus;
export const deleteDraftQuestionnaireStatus = (state: RootState) =>
	state.survey.draftQuestionnaireDeletedStatus;
export const getFilterDate = (state: RootState) => state.survey.dateFilter;
export const getIsLastScreenEndUser = (state: RootState) =>
	state.survey.isLastScreenEndUser;
export const questionnaireId = (state: RootState) =>
	state.survey.questionnaireId;
export const isFromNotification = (state: RootState) =>
	state.survey.surveyQuestionnaireData.surveyFormsDetails.isFromNotification;
export const getSurveyAudienceCount = (state: RootState) =>
	state.survey.surveyQuestionnaireData.audienceCount;
export const {
	setSurveyPayload,
	setAllSurveyResponse,
	setModifiedSurveyResponse,
	updateSurveyQuestionnaire,
	resetSurveyPayload,
	resetAppliedFilterData,
	setPageIndex,
	setRowsPerPageValue,
	setConfigurationData,
	setSurveyAudienceIncludedFilterResponse,
	setSurveyAudienceExcludedFilterResponse,
	setSelectedFilterResponse,
	setValidWWIDsResponse,
	setInvalidWWIDsResponse,
	removeSelectedFilters,
	setSelectedFilterRequestPayload,
	setDummySelectedFilterRequestPayload,
	setSurveyAudienceCount,
	setSurveyAudienceBlobName,
	resetAPIFlag,
	SetErrorMessage,
	setFilterRegion,
	setFilterRegionCountryData,
	setFilterJobFunction,
	setFilterAllJobFunctions,
	setAudienceFilterResponse,
	setExportUsersListResponse,
	setBatchReportRequestResponse,
	setExportUsersListExcelData,
	setSurveyAudienceIncludeClause,
	setSurveyAudienceExcludeClause,
	setSurveyAudienceFilterLabelJson,
	triggerResetSelectedAudienceFilter,
	setUserWWID,
	setUserWWIDStatus,
	setQuestionnaireDataStatus,
	setQuestionnaireStatus,
	setQuestionnaireDataByIdStatus,
	setQuestionnaireCopyDataStatus,
	setQuestionnaireByIdResponse,
	setHomeScreenActivatedTab,
	setActivateApplyFilter,
	setSurveyExAudienceBlobName,
	setQuestionnaireDeactiveateResponse,
	setDeleteDraftQuestionnaireResponse,
	setQuestionnaireDeactivatedStatus,
	setDeleteDraftQuestionnaireStatus,
	clearDropDownOptions,
	setFilterDate,
	setIsLastScreenEndUser,
	setQuestionnaireId,
} = surveySlice.actions;

export default surveySlice.reducer;

import ReactGA from 'react-ga4';
import _ from 'lodash';
import { IGraphData } from '../types/CommonTypes';
import { IGroup } from '../types/MappingTypes';
import { IRegion } from '../types/QuestionnaireResultsType';
import { ISurveyFormsQuestion } from '../types/SurveyProps';
import { IAudienceModalFilter } from '../types/CommonTypes';
import { commonLabels } from './CommonLabels';
import { Numbers } from './Enum';
import { getGAMeasurementId } from './Helpers';

/**
 * Method converts a date into the format  YYYY-MM-DD
 * @param aDate
 * @returns string
 */
const getFormattedDate = (aDate: Date | null) => {
	return aDate?.toISOString().split('T')[0];
};

const isFileTypeValidForExcel = (file: File): boolean => {
	return (
		file.type ===
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
		file.type === 'application/vnd.ms-excel'
	);
};

const getFormatedDate = (date: Date) => {
	return date?.toLocaleDateString('en-US', {
		day: '2-digit',
		month: 'short',
		year: 'numeric',
	});
};

const getGroupDropDownList = (GroupList: IGroup[]) => {
	return GroupList.map((group: IGroup) => ({
		key: group.groupId,
		value: group.groupName,
	}));
};
/**This method need to use everywhere for audiencepopup filter */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const peopleLeaderValidation = (
	includedFilters: IAudienceModalFilter,
	surveyAudienceIncludedFilters: [string, unknown][]
) => {
	const peopleLeaderValidationStatus: boolean[] = [];
	const subPeopleLeader: string[] = [];
	if (includedFilters?.people_leader?.length > 0) {
		Object.values(includedFilters?.people_leader)?.forEach(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(peopleLeader: any) => {
				subPeopleLeader.push(peopleLeader?.people_leader);
			}
		);
		subPeopleLeader?.map((item) => {
			surveyAudienceIncludedFilters?.map(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(surveyAudienceIncludedFiltersItem: any) => {
					if (
						surveyAudienceIncludedFiltersItem[0] === 'people_leader' &&
						surveyAudienceIncludedFiltersItem[1][item]?.length > 0
					) {
						peopleLeaderValidationStatus.push(
							_.intersection(
								surveyAudienceIncludedFiltersItem[1][item],
								subPeopleLeader
							).length > 0
						);
					} else {
						peopleLeaderValidationStatus.push(true);
					}
				}
			);
		});
	} else {
		peopleLeaderValidationStatus.push(true);
	}
	return [...new Set(peopleLeaderValidationStatus)];
};

const getParameterDropDownList = (paramList: string[]) => {
	return paramList.map((param: string) => ({
		key: param,
		value: param,
	}));
};

// Return the prepared data and maximum Count on y axis for plotting graph
export const prepareRegionalGraphData = (
	regionalData: IRegion[],
	type: string,
	overallEmployeeCount: number
) => {
	const preppedRegionalData: IGraphData[] = [];
	let maxCount = Numbers.zero;
	for (const [region, data] of Object.entries(regionalData)) {
		if (type === commonLabels.dataDisplayTypePercentage) {
			maxCount = Numbers.hundred;
			preppedRegionalData.push({
				label: region,
				values:
					overallEmployeeCount > Numbers.zero
						? [
								Number(
									(
										(data.impactedContingentWorkers * Numbers.hundred) /
										overallEmployeeCount
									).toFixed(Numbers.four)
								),
								Number(
									(
										(data.impactedEmployees * Numbers.hundred) /
										overallEmployeeCount
									).toFixed(Numbers.four)
								),
							]
						: [],
			});
		} else {
			maxCount = overallEmployeeCount;
			preppedRegionalData.push({
				label: region,
				values: [data.impactedContingentWorkers, data.impactedEmployees],
			});
		}
	}
	return [{ preppedRegionalData, maxCount }];
};

export const addPageViewEventForGA = (
	GAhitType: string,
	url: string,
	pageTitle: string
) => {
	ReactGA.initialize(`${getGAMeasurementId()}`);
	ReactGA.send({
		hitType: GAhitType,
		page: url,
		title: pageTitle,
	});
};
// Return the prepared data of key metrics
export const prepareKeyMetricsData = (
	type: string,
	impactedEmployees: number = Numbers.zero,
	totalEmployees: number = Numbers.zero
) => {
	if (type === commonLabels.dataDisplayTypePercentage) {
		if (totalEmployees > Numbers.zero) {
			return `${Number(
				Number(
					(Number(impactedEmployees) / Number(totalEmployees)) * Numbers.hundred
				).toFixed(Numbers.two)
			).toString()}%`;
		} else {
			return `${Numbers.zero}%`;
		}
	} else {
		return Number(impactedEmployees).toString();
	}
};

// return step labels to be displayed for questionnaire step
export const getSTEPLabels = () => {
	return [
		commonLabels.details,
		commonLabels.audienceLabel,
		commonLabels.questions,
		commonLabels.previewLabel,
		commonLabels.notificationLabel,
		commonLabels.publish,
	];
};

// return the chip dropdown options
export const chipDropdownOptions = () => {
	return [
		{ label: commonLabels.sevenDays, value: Numbers.seven },
		{ label: commonLabels.thirtyDays, value: Numbers.thirty },
		{ label: commonLabels.ninetyDays, value: Numbers.ninety },
	];
};

const isDuplicateQuestion = (
	formData: ISurveyFormsQuestion,
	surveyFormsQuestion: ISurveyFormsQuestion[]
) => {
	return surveyFormsQuestion.some((existingQuestion) => {
		const sortedExistingOptions = [...existingQuestion.options]
			.map((option) => option.options.toLowerCase().trim())
			.sort((a, b) => a.localeCompare(b));
		const sortedFormOptions = [...formData.options]
			.map((option) => option.options.toLowerCase().trim())
			.sort((a, b) => a.localeCompare(b));
		return (
			existingQuestion.questionId !== formData.questionId &&
			existingQuestion.questionType === formData.questionType &&
			existingQuestion.question?.toLowerCase().trim() ===
				formData.question?.toLowerCase().trim() &&
			JSON.stringify(sortedExistingOptions) ===
				JSON.stringify(sortedFormOptions)
		);
	});
};

const validateInputField = (input: string): boolean => {
	return /^[\w\- ]*$/.test(input);
};

const getUniqueGroupNames = (groups: IGroup[]): IGroup[] => {
	const uniqueGroupsMap = new Map<string, IGroup>();

	groups.forEach((group) => {
		if (!uniqueGroupsMap.has(group.groupName)) {
			uniqueGroupsMap.set(group.groupName, group);
		}
	});

	return Array.from(uniqueGroupsMap.values());
};

export {
	getFormattedDate,
	isFileTypeValidForExcel,
	getFormatedDate,
	validateInputField,
	isDuplicateQuestion,
	getGroupDropDownList,
	getParameterDropDownList,
	getUniqueGroupNames,
};

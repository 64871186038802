/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* value(includedFilters) data not coming in specific format and dynamic key like below so that we have used any
['mrc_code', [{mrc_code: 1410}]] */
import { Box, Typography, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { ReactComponent as ClearIcon } from '../assets/images/Clear-icon.svg';
import { STEPTheme } from '../utils/Theme';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import {
	resetAppliedFilterData,
	selectedFilterRequestPayloadData,
	setSelectedFilterRequestPayload,
	setSurveyAudienceBlobName,
	setSurveyAudienceCount,
	setSurveyAudienceExcludeClause,
	setSurveyAudienceFilterLabelJson,
	setSurveyAudienceIncludeClause,
	updateAudienceFilterListingData,
} from '../redux/SurveySlice';
import { IAudienceListingRequest } from '../types/CommonTypes';
import { getFilterLabelById } from '../utils/Helpers';
import { Numbers } from '../utils/Enum';
import {
	AudienceFilterLabels,
	initialFilterLabelJson,
} from '../utils/SurveyAudience';

interface BoxContainerProps extends BoxProps {
	isvertical: boolean | undefined;
	hasincludedfilterscount: boolean | undefined;
	hasexcludedfilterscount: boolean | undefined;
}

const StyledBox = styled(Box)({
	width: 'auto',
	top: '304px',
	left: '98px',
	padding: '4px 8px',
	gap: '8px',
	display: 'flex',
	color: STEPTheme.colors.white,
	background: STEPTheme.colors.white,
	cursor: 'pointer',
	boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
});

const SelectedFilterChipContainer = styled(Box)<{
	filterlabelstyles: React.CSSProperties | undefined;
}>(({ filterlabelstyles }) => ({
	display: 'grid',
	gridTemplateColumns: `repeat(${Numbers.three}, 1fr)`,
	gap: '8px',
	...filterlabelstyles,
}));

const BoxContainer = styled(Box)<BoxContainerProps>(
	({ isvertical, hasincludedfilterscount, hasexcludedfilterscount }) => ({
		display: 'flex',
		flexDirection: isvertical ? 'column' : 'row',
		margin: isvertical ? '13px 0px 25px 0px' : '',
		gap: '8px',
		marginBottom:
			hasincludedfilterscount && hasexcludedfilterscount ? '10px' : '',
	})
);

const StyledTypography = styled(Typography)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: '400',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	color: STEPTheme.colors.text,
});

const initialFilterValue = {
	people_leader: [],
	worker_type: [],
	work_region: [],
	work_country: [],
	sector: [],
	mrc_code: [],
	legal_entity: [],
	job_family: [],
	job_family_group: [],
	job_group: [],
	employee_function: [],
	manager_wwid: [],
	wwid: [],
};
interface SelectedFiltersProps {
	filterLabelStyles?: React.CSSProperties;
	isVertical: boolean;
}
const SelectedFilters: React.FC<SelectedFiltersProps> = ({
	filterLabelStyles,
	isVertical = false,
}) => {
	const dispatch = useAppDispatch();
	const filterRequestPayload = useAppSelector(selectedFilterRequestPayloadData);
	const includedFilters = filterRequestPayload.includeFilters
		? JSON.parse(filterRequestPayload.includeFilters)
		: (initialFilterValue as IAudienceListingRequest);
	const excludedFilters = filterRequestPayload.excludeFilters
		? JSON.parse(filterRequestPayload.excludeFilters)
		: (initialFilterValue as IAudienceListingRequest);

	const handleClose = (value: any, type: string) => {
		if (type === 'include') {
			includedFilters[value[0]] = [];
		} else {
			excludedFilters[value[0]] = [];
		}

		if (
			_.isEqual(initialFilterValue, includedFilters) &&
			_.isEqual(initialFilterValue, excludedFilters)
		) {
			dispatch(resetAppliedFilterData([]));
			dispatch(setSurveyAudienceCount(0));
			dispatch(
				setSelectedFilterRequestPayload({
					includeFilters: JSON.stringify(initialFilterValue),
					excludeFilters: JSON.stringify(initialFilterValue),
					includeClause: '',
					excludeClause: '',
					filterLabelJson: initialFilterLabelJson,
					pageSize: Numbers.ten,
					pageNumber: Numbers.one,
				})
			);
			dispatch(setSurveyAudienceBlobName(JSON.stringify(initialFilterValue)));
			dispatch(setSurveyAudienceIncludeClause(''));
			dispatch(setSurveyAudienceExcludeClause(''));
			dispatch(setSurveyAudienceFilterLabelJson(initialFilterLabelJson));
		} else {
			dispatch(
				setSelectedFilterRequestPayload({
					includeFilters: JSON.stringify({ ...includedFilters }),
					excludeFilters: JSON.stringify({ ...excludedFilters }),
					filterLabelJson: JSON.stringify({
						includeFilters: includedFilters,
						excludeFilters: excludedFilters,
					}),
					includeClause: '',
					excludeClause: '',
					pageSize: Numbers.ten,
					pageNumber: Numbers.one,
				})
			);
			if (includedFilters !== '{}') {
				dispatch(
					updateAudienceFilterListingData({
						includeFilters: JSON.stringify({ ...includedFilters }),
						excludeFilters: JSON.stringify({ ...excludedFilters }),
						filterLabelJson: JSON.stringify({
							includeFilters: includedFilters,
							excludeFilters: excludedFilters,
						}),
						includeClause: '',
						excludeClause: '',
						pageSize: Numbers.ten,
						pageNumber: Numbers.one,
					})
				);
			}
		}
	};
	let hasIncludedFiltersCount = false;
	let hasExcludedFiltersCount = false;
	if (Object.values(includedFilters).length > 0) {
		Object.values(includedFilters).forEach((includedFiltersValue: any) => {
			if (includedFiltersValue.length) {
				hasIncludedFiltersCount = true;
			}
		});
	}
	if (Object.values(excludedFilters).length > 0) {
		Object.values(excludedFilters).forEach((excludedFiltersValue: any) => {
			if (excludedFiltersValue.length) {
				hasExcludedFiltersCount = true;
			}
		});
	}

	return (
		<Box data-testid="selectedFilters">
			<BoxContainer
				isvertical={isVertical || undefined}
				hasincludedfilterscount={hasIncludedFiltersCount || undefined}
				hasexcludedfilterscount={hasExcludedFiltersCount || undefined}
			>
				{hasIncludedFiltersCount && (
					<Typography
						variant="h6"
						sx={{ minWidth: 'fit-content', marginTop: '4px' }}
					>
						{AudienceFilterLabels.includedFilters}
						{':'}
					</Typography>
				)}
				<SelectedFilterChipContainer filterlabelstyles={filterLabelStyles}>
					{Object.entries(includedFilters).map((includedFiltersValue: any) => {
						const filerValues: string[][] = [];
						includedFiltersValue &&
							includedFiltersValue[1].length > 0 &&
							Object.values(includedFiltersValue[1]).map((filterVal: any) => {
								filerValues.push(Object.values(filterVal));
							});
						return (
							includedFiltersValue &&
							includedFiltersValue[1].length > 0 && (
								<StyledBox>
									<ClearIcon
										style={{ minWidth: '14px', minHeight: '14px' }}
										onClick={() => handleClose(includedFiltersValue, 'include')}
									/>
									<StyledTypography>
										{getFilterLabelById(includedFiltersValue[0])}-
										{filerValues.join(', ')}
									</StyledTypography>
								</StyledBox>
							)
						);
					})}
				</SelectedFilterChipContainer>
			</BoxContainer>
			<BoxContainer
				isvertical={isVertical || undefined}
				hasincludedfilterscount={hasIncludedFiltersCount || undefined}
				hasexcludedfilterscount={hasExcludedFiltersCount || undefined}
			>
				{hasExcludedFiltersCount && (
					<Typography
						variant="h6"
						sx={{ minWidth: 'fit-content', marginTop: '4px' }}
					>
						{AudienceFilterLabels.excludedFilters}
						{':'}
					</Typography>
				)}
				<SelectedFilterChipContainer filterlabelstyles={filterLabelStyles}>
					{Object.entries(excludedFilters).map((excludedFiltersValue: any) => {
						const filerValues: string[][] = [];
						excludedFiltersValue &&
							excludedFiltersValue[1].length > 0 &&
							Object.values(excludedFiltersValue[1]).map((filterVal: any) => {
								filerValues.push(Object.values(filterVal));
							});
						return (
							excludedFiltersValue &&
							excludedFiltersValue[1].length > 0 && (
								<StyledBox>
									<ClearIcon
										style={{ minWidth: '14px', minHeight: '14px' }}
										onClick={() => handleClose(excludedFiltersValue, 'exclude')}
									/>
									<StyledTypography>
										{getFilterLabelById(excludedFiltersValue[0])}-
										{filerValues.join(', ')}
									</StyledTypography>
								</StyledBox>
							)
						);
					})}
				</SelectedFilterChipContainer>
			</BoxContainer>
		</Box>
	);
};

export default SelectedFilters;

import { AxiosRequestConfig } from 'axios';
import axiosIntercept from '../app/authentication/axiosIntercept';
import {
	IGroupReq,
	IGroupSaveReq,
	IGroupDeleteReq,
} from '../types/MappingTypes';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
	skipGlobalErrorHandling?: boolean;
}

export const FetchGroupList = async (requestPayload: IGroupReq) => {
	const URL = `questionnaire/${requestPayload.questionnaireId}/group`;
	return axiosIntercept.get(URL);
};

export const createUpdateGroup = async (
	request: IGroupSaveReq,
	config: CustomAxiosRequestConfig = {}
) => {
	let URL = '';
	if (request.groupId) {
		URL = `questionnaire/${request.questionnaireId}/group/${request.groupId}`;
	} else {
		URL = `questionnaire/${request.questionnaireId}/group`;
	}
	return axiosIntercept.post(URL, request, {
		...config,
		skipGlobalErrorHandling: true,
	} as AxiosRequestConfig);
};

export const deleteGroupList = async (requestPayload: IGroupDeleteReq) => {
	const URL = `questionnaire/${requestPayload.questionnaireId}/deletegroup`;
	const request = {
		groupIds: requestPayload.groupsToDelete,
	};
	return axiosIntercept.put(URL, request);
};

export const FetchSummitSurveyName = async (
	groupName: string,
	config: CustomAxiosRequestConfig = {}
) => {
	return axiosIntercept.get(`questionnaire/summit?groupName=${groupName}`, {
		...config,
		skipGlobalErrorHandling: true,
	} as AxiosRequestConfig);
};

export const FetchAudienceSubset = async (
	request: { questionnaireId: string },
	config: CustomAxiosRequestConfig = {}
) => {
	const URL = `users/groups/questionnaire/${request.questionnaireId}`;
	return axiosIntercept.get(URL, {
		...config,
		skipGlobalErrorHandling: true,
	} as AxiosRequestConfig);
};

import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	MenuItem,
	Paper,
	Select,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material';
import { orderBy, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useParams } from 'react-router-dom';
import ArrowUpward from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownward from '@mui/icons-material/ArrowDownwardOutlined';
import { ReactComponent as DownArrow } from '../../../assets/images/Doarrow-narrow-up.svg';
import { useAppDispatch, useAppSelector } from '../../../app/Hooks';
import {
	IEmployeeDetailsCollection,
	IEmployeeDetailsCollectionData,
	IListViewReq,
} from '../../../types/QuestionnaireResultsType';
import {
	getListViewResultsRecords,
	listViewResultAPICompletionStatus,
	resultListViewRecord,
	setModifiedListResultResponse,
	setResultAPICompletionStatus,
	setPageIndex,
	setRowsPerPageValue,
	pageIndex,
	pageSize,
} from '../../../redux/QuestionnaireResultsSlice';
import { tableHead } from '../../../utils/Filter';
import { STEPTheme } from '../../../utils/Theme';
import {
	AudienceFilterLabels,
	initialFilterLabelJson,
	initialFilterValue,
} from '../../../utils/SurveyAudience';
import SelectedFilters from '../../../components/SelectedFilters';
import {
	configurationData,
	resetAppliedFilterData,
	selectedFilterRequestPayloadData,
	setSelectedFilterRequestPayload,
	setSurveyAudienceBlobName,
	setSurveyAudienceExcludeClause,
	setSurveyAudienceFilterLabelJson,
	setSurveyAudienceIncludeClause,
} from '../../../redux/SurveySlice';
import {
	getGroupDropDownList,
	getUniqueGroupNames,
} from '../../../utils/CommonMethods';
import ChipGroup from '../../../components/common/ChipGroup';
import { groupsList } from '../../../redux/MappingSlice';
import StyledTooltip from '../../../components/StyledTooltip';
import { Numbers, PageRows } from '../../../utils/Enum';
import { FilterButtonLabel } from '../../../utils/QuestionnaireResult';
import { commonLabels } from '../../../utils/CommonLabels';
import AudiencePopup from '../../../components/mapping/AudiencePopup';
import {
	IAudienceListingRequest,
	IChipGroup,
} from '../../../types/CommonTypes';
import { getTimeInLastRefreshFormat } from '../../../utils/DateFormatting';
import NoResultContentFound from '../../../components/questionnaire-results/NoResultContentFound';
import Loader from '../../../components/Loader';
import { API_STATUS_CONSTANT } from '../../../constants/StepConstants';
import { questionnaireAPIKeys } from '../../../utils/ApiKeys';
import { slotProps } from '../../../components/questions/common/QuestionStyles';

const ListTableView: React.FC<{
	preFilterValue: string;
	preSelectedDays: number;
}> = ({ preFilterValue, preSelectedDays }) => {
	const HeadText = styled('div')({
		width: 'auto',
		minHeight: '22px',
		fontFamily: 'JohnsonText-Regular',
		fontSize: '16px',
		fontWeight: '700',
		lineHeight: '16px',
		letterSpacing: '1px',
		textAlign: 'left',
		display: 'flex',
		cursor: 'pointer',
		svg: {
			cursor: 'pointer',
			width: '22px',
			height: '18px',
		},
		'.up-arrow': {
			svg: {
				transform: 'rotate(180deg)',
			},
		},
	});
	const StyledDropdownLabel = styled(Typography)({
		fontSize: '14px',
		fontWeight: 500,
		fontFamily: 'JohnsonText-Medium',
	});
	const FilterButton = styled(Button)({
		width: '100px',
		height: '24px',
		borderRadius: '8px',
		backgroundColor: STEPTheme.colors.text,
		color: STEPTheme.colors.white,
		fontFamily: 'JohnsonText-Regular',
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: '16px',
		align: 'center',
		display: 'flex',
		marginLeft: 'auto',
		'&:hover': {
			backgroundColor: STEPTheme.colors.text,
			textDecoration: 'none !important',
			border: `1px solid ${STEPTheme.colors.text} !important`,
			filter: 'blur(1px)',
		},
	});
	const Reset = styled(Button)({
		display: 'flex',
		flexWrap: 'wrap',
		width: '55px',
		fontFamily: 'JohnsonText-Regular',
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '16px',
		letterSpacing: '0px',
		textAlign: 'left',
		color: STEPTheme.colors.blue,
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'initial',
			textDecoration: 'none',
		},
	});
	const FilterButtonsBox = styled(Box)({
		display: 'flex',
		gap: '10px',
		marginLeft: 'auto',
		marginBottom: 'auto',
		marginTop: '10px',
	});
	const StyledSelectField = styled(Select)({
		border: '1px',
		color: STEPTheme.colors.darkGray,
		marginTop: '4px',
		borderRadius: '8px',
		minWidth: '300px',
		'&.MuiInputBase-root .MuiSelect-select': {
			padding: '9px 10px',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: STEPTheme.colors.primary,
		},
		'&.Mui-disabled': {
			backgroundColor: `${STEPTheme.colors.disabledButton} !important`,
			borderColor: `${STEPTheme.colors.linkGray} !important`,
		},
	});

	const groupListFetched = useAppSelector(groupsList);
	//getting the unique group name
	const uniqueGroupList = getUniqueGroupNames(groupListFetched);
	const groupListDropDownOptions = getGroupDropDownList(uniqueGroupList);
	const [autoSelectBySeeMore, setAutoSelectBySeeMore] = useState(
		preFilterValue !== ''
	);
	const [autoSelectedDays, setAutoSelectedDays] =
		useState<number>(preSelectedDays);
	const [groupsSelected, setGroupsSelected] = useState<string[]>([]);
	const [isAllGroupsSelected, setIsAllGroupsSelected] = useState(false);
	const { questionnaireID } = useParams<{ questionnaireID: string }>();
	// Convert questionnaireID to string and store in questionnaireId
	const questionnaireId = `${questionnaireID}`;
	/**Filter state variable Code Start */
	const [filterSelectionOpen, setFilterSelectionOpen] = useState(false);
	const filterRequestPayload = useAppSelector(selectedFilterRequestPayloadData);
	/**Filter state variable Code end */
	/**Table variables */
	const configs = useAppSelector(configurationData);
	const resultTablePageIndex: number = useAppSelector(pageIndex);
	const resultTablePageSize: number = useAppSelector(pageSize);
	const [page, setPage] = React.useState(Numbers.zero);
	const rowPage = configs?.rows_per_page?.map((i: number) => Number(i));
	const defaultRowsPerPage = rowPage
		? rowPage[Numbers.zero]
		: PageRows.firstOption;
	const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
	const [resultNameSortObj, setResultNameSortObj] = useState(' ');
	const [resultEmailSortObj, setResultEmailSortObj] = useState(' ');
	const [resultWwidSortObj, setResultWwidSortObj] = useState(' ');
	const [resultSectorSortObj, setResultSectorSortObj] = useState(' ');
	const [resultRegionSortObj, setResultRegionSortObj] = useState(' ');
	const [resultRespondedSortObj, setResultRespondedSortObj] = useState(' ');
	const [resultCompletionDateSortObj, setResultCompletionDateSortObj] =
		useState(' ');
	const listViewDataArray: IEmployeeDetailsCollectionData =
		useAppSelector(resultListViewRecord);
	/**Table variables */
	const dispatch = useAppDispatch();
	const includedFilters = filterRequestPayload.includeFilters
		? JSON.parse(filterRequestPayload.includeFilters)
		: (initialFilterValue as unknown as IAudienceListingRequest);
	const excludedFilters = filterRequestPayload.excludeFilters
		? JSON.parse(filterRequestPayload.excludeFilters)
		: (initialFilterValue as unknown as IAudienceListingRequest);
	const [filtersApplied, setFiltersApplied] = useState(false);
	const [showLoader, setShowLoader] = React.useState(true);
	const APICompletionStatus = useAppSelector(listViewResultAPICompletionStatus);

	const defaultChips = [
		{ text: commonLabels.RESPONDED, selected: true, visible: true },
		{ text: commonLabels.NOTRESPONDED, selected: false, visible: true },
		{ text: commonLabels.RISKOVERDUE, selected: false, visible: true },
		{ text: commonLabels.NON_COMPLIANT, selected: false, visible: true },
		{
			text: commonLabels.MULTIPLESURVEYGROUPS,
			selected: false,
			visible: true,
		},
		{ text: commonLabels.NOTINSUMMIT, selected: false, visible: false },
		{ text: commonLabels.SUMMITMAPPED, selected: false, visible: true },
	];

	useEffect(() => {
		handleResetAllFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!autoSelectBySeeMore) {
			setNotInSummitAsDefaultChip(
				groupsSelected.length !== Numbers.zero,
				groupsSelected
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupsSelected, groupListFetched]);

	useEffect(() => {
		handleListViewFilter(chips, page, rowsPerPage, autoSelectedDays);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage]);

	useEffect(() => {
		if (
			APICompletionStatus === API_STATUS_CONSTANT.SUCCESS ||
			APICompletionStatus === API_STATUS_CONSTANT.FAILED
		) {
			setShowLoader(false);
			dispatch(setResultAPICompletionStatus(API_STATUS_CONSTANT.IDLE));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [APICompletionStatus]);

	useEffect(() => {
		setPage(resultTablePageIndex);
		setRowsPerPage(resultTablePageSize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listViewDataArray]);

	useEffect(() => {
		if (groupsSelected.length > Numbers.zero) {
			setIsAllGroupsSelected(
				groupListDropDownOptions.length === groupsSelected.length
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupsSelected]);

	useEffect(() => {
		if (preFilterValue !== '') {
			handleSelect(preFilterValue, preSelectedDays, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preFilterValue]);

	useEffect(() => {
		setFiltersApplied(
			!isEqual(includedFilters, initialFilterValue) ||
				!isEqual(excludedFilters, initialFilterValue)
		);
		if (filtersApplied) {
			handleListViewFilter(chips, page, rowsPerPage, autoSelectedDays);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersApplied, filterRequestPayload]);

	const handleResetAllFilter = () => {
		dispatch(resetAppliedFilterData([]));
		dispatch(
			setSelectedFilterRequestPayload({
				includeFilters: JSON.stringify(initialFilterValue),
				excludeFilters: JSON.stringify(initialFilterValue),
				includeClause: '',
				excludeClause: '',
				filterLabelJson: initialFilterLabelJson,
			})
		);
		dispatch(setSurveyAudienceBlobName(JSON.stringify(initialFilterValue)));
		dispatch(setSurveyAudienceIncludeClause(''));
		dispatch(setSurveyAudienceExcludeClause(''));
		dispatch(setSurveyAudienceFilterLabelJson(initialFilterLabelJson));
	};
	/**Filter state varriable Code Start */
	const handleFilterModalOpen = () => {
		setFilterSelectionOpen(true);
	};
	/**Filter state varriable Code end */
	const [chips, setChips] = useState<IChipGroup['chips']>(defaultChips);

	const transformSelectedChip = (
		chipText: string,
		modifiedChips: IChipGroup['chips']
	) => {
		const updatedChips = modifiedChips.map(
			(chip: { text: string; selected: boolean; visible?: boolean }) => {
				return chip.text === chipText
					? { ...chip, selected: !chip.selected }
					: { ...chip, selected: false };
			}
		);
		setChips([...updatedChips]);
		return updatedChips;
	};

	const handleSelect = (
		chipText: string,
		days: number,
		isMenuItemClicked: boolean,
		isFirstPreSelected = false
	) => {
		setChips((prevChips) => {
			const updatedChips = prevChips.map((chip) => {
				if (chip.text === chipText && (!chip.selected || isMenuItemClicked)) {
					return { ...chip, selected: true };
				} else {
					return { ...chip, selected: false };
				}
			});
			// Call handleListViewFilter with the updated chips
			handleListViewFilter(
				updatedChips,
				Numbers.zero,
				defaultRowsPerPage,
				days
			);
			// Reset Auto selected flag and days once a select has been called
			setAutoSelectBySeeMore(false);
			setSelectedDays(
				updatedChips,
				isMenuItemClicked,
				days,
				isFirstPreSelected
			);

			return updatedChips;
		});
		setRowsPerPage(defaultRowsPerPage);
		dispatch(setRowsPerPageValue(defaultRowsPerPage));
		setPage(Numbers.zero);
		dispatch(setPageIndex(Numbers.zero));

		// Reset sorting state when a new chip is selected
		clearAllSorting();
	};

	/**
	 * @description set Days to render on dropdown of chips.
	 * @param updatedChips
	 * @param isMenuItemClicked
	 * @param days
	 * @param isFirstPreSelected
	 */
	const setSelectedDays = (
		updatedChips: IChipGroup['chips'],
		isMenuItemClicked: boolean,
		days: number,
		isFirstPreSelected?: boolean
	) => {
		if (isFirstPreSelected && !isMenuItemClicked) {
			const chipsText =
				updatedChips.find(
					(item) => item.text === commonLabels.RISKOVERDUE && item.selected
				)?.text || '';
			setAutoSelectedDays(
				chipsText === commonLabels.RISKOVERDUE ? Numbers.seven : Numbers.zero
			);
		} else {
			setAutoSelectedDays(days);
		}
	};

	const createChipsPayload = (selectedChipText: string | undefined) => {
		const metricObj = {
			metricName: '',
			metricSubType: '',
		};
		const EMPLOYEE = 'employees';
		if (
			selectedChipText === undefined &&
			groupsSelected.length === Numbers.zero
		) {
			metricObj.metricName = ``;
			metricObj.metricSubType = ``;
			return metricObj;
		} else if (
			groupsSelected.length === Numbers.zero &&
			selectedChipText === commonLabels.NOTINSUMMIT
		) {
			selectedChipText = commonLabels.RESPONDED;
			transformSelectedChip(selectedChipText, defaultChips);
		} else if (!selectedChipText && groupsSelected.length > Numbers.zero) {
			metricObj.metricName = `${EMPLOYEE}summitgroupstatus`;
			metricObj.metricSubType = ``;
			return metricObj;
		} else {
			/* empty */
		}
		switch (selectedChipText) {
			case commonLabels.RESPONDED:
				metricObj.metricSubType = `responded`;
				break;
			case commonLabels.NOTRESPONDED:
				metricObj.metricSubType = 'not responded';
				break;
			case commonLabels.EMPLOYEE:
				metricObj.metricName = `${EMPLOYEE}`;
				break;
			case commonLabels.CONTIGENTWORKER:
				metricObj.metricName = `${EMPLOYEE}ContigentWorker`;
				break;
			case commonLabels.NON_COMPLIANT:
				metricObj.metricName = `${EMPLOYEE}AlreadyOverDue`;
				break;
			case commonLabels.RISKOVERDUE:
				metricObj.metricName = `${EMPLOYEE}RiskOverDue`;
				break;
			case commonLabels.ALREADYOVERDUE:
				metricObj.metricName = `${EMPLOYEE}AlreadyOverDue`;
				break;
			case commonLabels.MULTIPLESURVEYGROUPS:
				metricObj.metricName = `${EMPLOYEE}MultiPageSurveyGroup`;
				break;
			case commonLabels.NOTINSUMMIT:
				metricObj.metricName = `${EMPLOYEE}summitgroupstatus`;
				metricObj.metricSubType = `notInSummit`;
				break;
			case commonLabels.SUMMITMAPPED:
				metricObj.metricName = `${EMPLOYEE}summitgroupstatus`;
				metricObj.metricSubType = `InSummit`;
				break;
			default:
				metricObj.metricSubType = `responded`;
				break;
		}
		return metricObj;
	};

	/**
	 * @description single group selection from list
	 * @param groupNameSelected
	 */
	const handleGroupSelect = (groupNameSelected: string) => {
		// Finding the selected group from unique group list
		const selectedGroupOption = uniqueGroupList.find(
			(group) => group.groupName === groupNameSelected
		);
		if (selectedGroupOption) {
			// getting the group ID
			const groupId = selectedGroupOption.groupId;
			const isGroupAlreadySelected = groupsSelected.includes(groupId);
			if (isGroupAlreadySelected) {
				setGroupsSelected((oldArray) =>
					oldArray.filter((oldItem) => oldItem !== groupId)
				);
			} else {
				setGroupsSelected((oldArray) => [...oldArray, groupId]);
			}
		}
	};

	const setNotInSummitAsDefaultChip = (
		isGrpupSelected: boolean,
		groupIds: string[]
	) => {
		let updatedChips = defaultChips;
		if (!isGrpupSelected) {
			setChips(updatedChips);
		} else {
			updatedChips = chips.map(
				(chip: { text: string; selected: boolean; visible?: boolean }) => {
					return chip.text !== commonLabels.NOTINSUMMIT
						? { ...chip, selected: false, visible: false }
						: { ...chip, selected: true, visible: true };
				}
			);
			setChips([...updatedChips]);
		}
		handleListViewFilter(
			updatedChips,
			page,
			rowsPerPage,
			Numbers.one,
			groupIds
		);
	};

	const handleListViewFilter = (
		updatedChips: IChipGroup['chips'],
		pageNum: number,
		pageSz: number,
		days: number,
		groupIds?: string[] | undefined
	) => {
		const selectedChipText = updatedChips?.find(
			(chipsItem: { text: string; selected: boolean; visible?: boolean }) =>
				chipsItem.selected
		)?.text;
		const payload = {
			requestFilterPayload: createRequestPayload(
				createChipsPayload(selectedChipText),
				pageNum,
				pageSz,
				days,
				groupIds
			),
			questionniareId: questionnaireId,
		};
		setShowLoader(true);
		dispatch(getListViewResultsRecords(payload));
	};

	const createRequestPayload = (
		metricObj: Record<string, string>,
		pageNum: number,
		pageSz: number,
		days: number,
		groupIds?: string[] | undefined
	) => {
		const listRequestFilterPayload: IListViewReq = {
			includeFilter: JSON.stringify({ ...includedFilters }),
			excludeFilter: JSON.stringify({ ...excludedFilters }),
			days: days,
			pageNumber: Number(pageNum + Numbers.one),
			pageSize: pageSz,
			metricName: metricObj?.metricName || '',
			metricSubType: metricObj?.metricSubType || '',
			groupId: payloadGroupIds(groupIds),
		};
		return listRequestFilterPayload;
	};

	const payloadGroupIds = (groupIds?: string[] | undefined) => {
		return groupIds && groupIds?.length > Numbers.zero
			? groupIds
			: groupsSelected;
	};

	/**Table Pagination Methods */
	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
		dispatch(setPageIndex(newPage));
	};
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(Number(event.target.value));
		dispatch(setRowsPerPageValue(Number(event.target.value)));
		dispatch(setPageIndex(Numbers.zero));
	};
	const clearAllSorting = () => {
		setResultNameSortObj(' ');
		setResultEmailSortObj(' ');
		setResultWwidSortObj(' ');
		setResultSectorSortObj(' ');
		setResultRegionSortObj(' ');
		setResultRespondedSortObj(' ');
		setResultCompletionDateSortObj(' ');
	};
	/**Table Pagination Methods */

	/**
	 * @description Sorting arrow making and functionality.
	 * @param stateName
	 * @param columnName
	 * @param setStateMethod
	 * @returns
	 */
	const renderAscDesArraow = (
		stateName: string,
		columnName: string,
		setStateMethod: (value: React.SetStateAction<string>) => void
	) => {
		return (
			<Fragment>
				{stateName === ' ' && (
					<div className="up-arrow">
						<DownArrow
							onClick={() => {
								clearAllSorting();
								arrowClick(stateName, columnName, setStateMethod);
							}}
						/>
					</div>
				)}
				{stateName === questionnaireAPIKeys.desc && (
					<ArrowDownward
						data-testid="sortByNameDesc"
						color="error"
						fontSize="inherit"
						className="text-base"
						onClick={() => {
							clearAllSorting();
							arrowClick(stateName, columnName, setStateMethod);
						}}
					/>
				)}
				{stateName === questionnaireAPIKeys.asc && (
					<ArrowUpward
						data-testid="sortByName"
						color="error"
						fontSize="inherit"
						className="text-base"
						onClick={() => {
							arrowClick(stateName, columnName, setStateMethod);
						}}
					/>
				)}
			</Fragment>
		);
	};
	/**arrow click common method */
	const arrowClick = (
		stateValue: string,
		columnName: string,
		setStateMethod: (value: React.SetStateAction<string>) => void
	) => {
		const ascDescVal =
			stateValue === questionnaireAPIKeys.desc
				? ' '
				: stateValue === questionnaireAPIKeys.asc
					? questionnaireAPIKeys.desc
					: questionnaireAPIKeys.asc;
		setStateMethod(ascDescVal);
		if (stateValue === questionnaireAPIKeys.desc) {
			return;
		}

		// Added logic to handle sorting for resultCompletionDate column
		let sortedData;
		if (columnName === 'questionnaireCompletionDate') {
			sortedData = orderBy(
				listViewDataArray?.employeeDetailsCollection,
				(row) => {
					const dateHeader = getDateHeader();
					return dateHeader === commonLabels.DUE_DATE
						? row?.questionnaireDueDate
						: row?.questionnaireCompletionDate;
				},
				[ascDescVal as 'asc']
			);
		} else {
			sortedData = orderBy(
				listViewDataArray?.employeeDetailsCollection,
				[columnName],
				[ascDescVal as 'asc']
			);
		}

		dispatch(
			setModifiedListResultResponse({
				totalCount: listViewDataArray?.totalCount,
				employeeDetailsCollection: sortedData,
			})
		);
	};

	/**
	 * @description Decide date column Text
	 * @returns string text
	 */
	const getDateHeader = () => {
		const selectedChip = chips.find((item) => item.selected)?.text;
		const dueDate = [
			commonLabels.NOTRESPONDED,
			commonLabels.ALREADYOVERDUE,
			commonLabels.NON_COMPLIANT,
			commonLabels.RISKOVERDUE,
		].includes(selectedChip as string);
		return dueDate ? commonLabels.DUE_DATE : commonLabels.COMPLETION_DATE;
	};

	/**
	 * @description create dropdown list with Menu Item
	 * @returns html
	 */
	const createGroupDropdownList = () => {
		return groupListDropDownOptions.map((dropDownItem) => {
			const selectedGroupOption = uniqueGroupList.find(
				(group) => group.groupName === dropDownItem.value
			);
			const groupId = selectedGroupOption?.groupId || '';
			return (
				<MenuItem key={dropDownItem.key} value={dropDownItem.value}>
					<FormControlLabel
						control={
							<Checkbox
								id={`group_list_${dropDownItem.key}`}
								value={dropDownItem.value}
								onChange={(e) => {
									handleGroupSelect && handleGroupSelect(e.target.value);
								}}
								checked={groupsSelected.includes(groupId)}
							/>
						}
						label={dropDownItem.value}
						sx={{ width: '100%' }}
					/>
				</MenuItem>
			);
		});
	};

	/**
	 * @description click event for Group List all check box
	 */
	const handleAllGroupSelect = () => {
		const groupAllSelected = !isAllGroupsSelected;
		setIsAllGroupsSelected(groupAllSelected);
		let items: string[] = [];
		groupListDropDownOptions.forEach((item: Record<string, string>) => {
			if (!groupAllSelected && groupsSelected.length > Numbers.zero) {
				setGroupsSelected((oldArray) =>
					oldArray.filter((oldItem) => oldItem !== item.key)
				);
			} else {
				items = [...items, item.key];
				setGroupsSelected(items);
			}
		});
	};
	return (
		<Fragment>
			<Loader isLoading={showLoader} />
			<Box
				sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '10px' }}
			>
				<StyledDropdownLabel>{commonLabels.groupLabel}</StyledDropdownLabel>
				<StyledSelectField
					placeholder={commonLabels.selectLabel}
					displayEmpty
					disabled={groupListDropDownOptions.length === Numbers.zero}
					multiple
					value={[]}
					autoWidth={true}
					renderValue={() =>
						groupsSelected.length > Numbers.zero
							? `${groupsSelected.length} Selected`
							: commonLabels.selectLabel
					}
				>
					{/* code for All Checkbox */}
					<MenuItem>
						<FormControlLabel
							control={
								<Checkbox
									value={Numbers.zero}
									onChange={() => {
										handleAllGroupSelect && handleAllGroupSelect();
									}}
									checked={isAllGroupsSelected}
								/>
							}
							label={commonLabels.allText}
						/>
					</MenuItem>
					{createGroupDropdownList()}
				</StyledSelectField>
			</Box>
			<Box sx={{ display: 'flex', marginTop: '30px', width: '100%' }}>
				<ChipGroup
					chips={chips}
					color={STEPTheme.colors.radianceBlue}
					backgroundColor={STEPTheme.colors.cyanBlue}
					onSelect={(
						chipText: string,
						days: number,
						isMenuItemClicked: boolean,
						isFirstPreSelected = false
					) =>
						handleSelect(chipText, days, isMenuItemClicked, isFirstPreSelected)
					}
					preSelectedDays={autoSelectedDays}
				/>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'row', flex: 'auto' }}>
				<Box sx={{ width: '80%', float: 'left' }}>
					<SelectedFilters isVertical={true} />
				</Box>
				<FilterButtonsBox>
					{filtersApplied && (
						<Reset
							data-testid="result-reset-all-button"
							className="pr-0 justify-end"
							variant="text"
							onClick={handleResetAllFilter}
						>
							{AudienceFilterLabels.resetAll}
						</Reset>
					)}
					<FilterButton
						variant="outlined"
						color="secondary"
						onClick={handleFilterModalOpen}
					>
						{FilterButtonLabel}
					</FilterButton>
				</FilterButtonsBox>
			</Box>
			<TableContainer
				component={Paper}
				elevation={1}
				sx={{
					boxShadow: 'none',
					height: '420px',
					marginTop: '30px',
					backgroundColor: 'transparent',
				}}
			>
				<Table
					stickyHeader
					aria-label="sticky table"
					sx={{ background: `${STEPTheme.colors.background}` }}
				>
					<TableHead>
						<TableRow>
							<TableCell sx={{ background: `${STEPTheme.colors.background}` }}>
								<HeadText>
									{commonLabels.NAME}
									{renderAscDesArraow(
										resultNameSortObj,
										'name',
										setResultNameSortObj
									)}
								</HeadText>
							</TableCell>
							<TableCell sx={{ background: `${STEPTheme.colors.background}` }}>
								<HeadText>
									{commonLabels.WWID}
									{renderAscDesArraow(
										resultWwidSortObj,
										'wwid',
										setResultWwidSortObj
									)}
								</HeadText>
							</TableCell>
							<TableCell sx={{ background: `${STEPTheme.colors.background}` }}>
								<HeadText>
									{commonLabels.EMAIL}
									{renderAscDesArraow(
										resultEmailSortObj,
										'emailId',
										setResultEmailSortObj
									)}
								</HeadText>
							</TableCell>
							<TableCell sx={{ background: `${STEPTheme.colors.background}` }}>
								<HeadText>
									{commonLabels.SECTOR}
									{renderAscDesArraow(
										resultSectorSortObj,
										'sector',
										setResultSectorSortObj
									)}
								</HeadText>
							</TableCell>
							<TableCell sx={{ background: `${STEPTheme.colors.background}` }}>
								<HeadText>
									{commonLabels.REGION}
									{renderAscDesArraow(
										resultRegionSortObj,
										'region',
										setResultRegionSortObj
									)}
								</HeadText>
							</TableCell>
							<TableCell sx={{ background: `${STEPTheme.colors.background}` }}>
								<HeadText>
									{commonLabels.RESPONDED}
									{renderAscDesArraow(
										resultRespondedSortObj,
										'isRespondedByWWID',
										setResultRespondedSortObj
									)}
								</HeadText>
							</TableCell>
							<TableCell sx={{ background: `${STEPTheme.colors.background}` }}>
								<HeadText>
									{getDateHeader()}
									{renderAscDesArraow(
										resultCompletionDateSortObj,
										'questionnaireCompletionDate',
										setResultCompletionDateSortObj
									)}
								</HeadText>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody
						sx={{
							borderRadius: '4px',
							background: `${STEPTheme.colors.white}`,
						}}
					>
						{listViewDataArray?.employeeDetailsCollection?.length >
						Numbers.zero ? (
							listViewDataArray?.employeeDetailsCollection?.map(
								(row: IEmployeeDetailsCollection, index: number) => (
									<TableRow key={index}>
										<TableCell className="break-all" component="th" scope="row">
											{row.name}
										</TableCell>
										<TableCell>{row.wwid}</TableCell>
										<TableCell>{row.emailId}</TableCell>
										<TableCell>{row.sector}</TableCell>
										<TableCell>{row.region}</TableCell>
										<TableCell>{row.isRespondedByWWID}</TableCell>
										<TableCell>
											{getTimeInLastRefreshFormat(
												getDateHeader() === commonLabels.DUE_DATE
													? row?.questionnaireDueDate
													: row?.questionnaireCompletionDate,
												'ddmmyyyy'
											)}
										</TableCell>
									</TableRow>
								)
							)
						) : (
							<TableRow>
								<TableCell colSpan={7} align="center">
									<NoResultContentFound
										lineOneMessage={commonLabels.noDataLineOne}
										lineTwoMessage={commonLabels.noDataLineTwo}
										heading={''}
									/>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{listViewDataArray?.totalCount > Numbers.zero && (
				<Grid container sx={{ justifyContent: 'flex-end' }}>
					<StyledTooltip
						title={tableHead.tooltips.ROWS_PER_PAGE}
						placement="left"
					>
						<TablePagination
							data-testid="paginationWrapper"
							rowsPerPageOptions={rowPage}
							component="div"
							count={listViewDataArray?.totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							slotProps={slotProps}
						/>
					</StyledTooltip>
				</Grid>
			)}
			{filterSelectionOpen && (
				<AudiencePopup
					data-testid="audience-popup"
					closePopup={() => {
						setFilterSelectionOpen(false);
					}}
				/>
			)}
		</Fragment>
	);
};

export default ListTableView;
